import {ListDataSource} from "@/data/List/ListDataSource";
import {IBaseConfig} from "@/ioc/types";
import axios from "axios";
import {toQueryString} from "@/cms-services/helpers";

export class ProductModificationListDataSource extends ListDataSource {
    constructor(config: IBaseConfig = {}) {
        super({
            className: "ProductModification",
            config,
        });
    }

    public async uploadCsv(event: any, productId: number | null) {
        try {
            const {data} = await axios.post(
                `${this.baseUrl}/import/csv${toQueryString({productId})}`,
                event.data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "*/*",
                    },
                    onUploadProgress(e) {
                        event.onProgress(e, event.file);
                    },
                },
            );

            this.items = [...data, ...this.items];
        } catch (err) {
            throw err;
        }
    }
}